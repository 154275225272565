var $ = require("jquery");
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const isLargeScreen = window.matchMedia("(min-width: 1025px)").matches;
const isSmallScreen = window.matchMedia("(max-width: 1024px)").matches;


/* ====================== OUR VALUES ANIM ======================  */
    function scrollOurValues() {
      const ourValues = document.querySelector('.our-values');
      const sliderTrack = document.querySelector('.our-values__slider__track');
      const valuesCards = document.querySelectorAll('.values-card');
      const containerWidth = document.querySelector('.our-values .container').offsetWidth;
      let trackWidth = 0;

      // Calculate total width considering padding and margins
      valuesCards.forEach(card => {
        const cardStyles = window.getComputedStyle(card);
        trackWidth += card.offsetWidth + parseFloat(cardStyles.marginLeft) + parseFloat(cardStyles.marginRight);
      });

      // Adjust end position based on container width, multiplied by a factor
      const endPosition = Math.min(trackWidth - (containerWidth * 0.01) , 10000);

      gsap.timeline({
        scrollTrigger: {
          trigger: ourValues,
          start: 'center center',
          pin: true,
          end: () => endPosition, // Pin until the end of the slider within the container
          scrub: 2.5,
          onUpdate: (self) => {
            const progress = self.progress;
            const targetScroll = trackWidth - containerWidth;
            const scrollAmount = targetScroll * progress;
            gsap.to(sliderTrack, { x: -scrollAmount, ease: "power2.out" }); // Apply easing directly here
          },
          onLeaveBack: () => {
            ScrollTrigger.getById('ourValuesPin').kill(); // Unpin the section
          }
        }
      })
      .addLabel('start')
      .to(ourValues, {duration: 0.5, autoAlpha: 1}); // Optionally fade in the section
    }

    if($('.our-values').length) {
        scrollOurValues();
    }

/* ====================== GSAP MEDIA MATCH ======================  */
    ScrollTrigger.matchMedia({
        "(max-width: 767px)": function() {
            gsap.utils.toArray(".featured-projects .project-block").forEach((panel, i) => {
                gsap.to(panel.querySelectorAll(".project-block__image"), {
                    scrollTrigger: {
                        trigger: panel,
                        start: 'top bottom',
                        end: 'center center',
                        scrub: 1, // Smoothly transition the effect as you scroll
                        once: true,
                        toggleActions: "play none none reverse", // Reverse the animation when scrolling up after completion
                        markers: false,
                    },
                    opacity: .6,
                    ease: "linear",
                });
            });
        },
        "(max-width: 1024px)": function() {
             gsap.utils.toArray(".showreel").forEach((showreel, i) => {
                var showReelTL = gsap.timeline({
                    scrollTrigger: {
                        trigger: showreel,
                        start: "top 80%",
                        end: "bottom 80%",
                        scrub: true,
                        markers: false,
                    },
                });

                showReelTL.to(showreel, {
                    scale: 1,
                })
             });
        },
        "(min-width: 768px)": function() {
                /* ====================== IMAGE GRID ANIM ======================  */
                if($(".image-grid:not(.image-grid--alt)").length) {
                    var imageGridTL = gsap.timeline({
                        scrollTrigger: {
                            trigger: ".image-grid:not(.image-grid--alt)",
                            start: "top bottom", // adjust as needed
                            end: "bottom center 10%", // adjust as needed
                            scrub: 2,
                        }
                    })

                    imageGridTL.to(".image-block--sm", {
                        y: 0,
                        ease: "linear",
                    }, 0).to(".image-block--lg", {
                        y: 100,
                        ease: "linear",
                    }, 0);
                }

                if($(".image-grid--alt").length) {
                    var imageGridAltTL = gsap.timeline({
                        scrollTrigger: {
                          trigger: ".image-grid--alt",
                          start: "top bottom", // adjust as needed
                          end: "bottom center", // adjust as needed
                          scrub: 2, // Smoothly animates elements as you scroll
                          markers: false,
                        }
                    })

                    imageGridAltTL.to(".image-block--sm", {
                        y: 250,
                        ease: "linear",
                    }, 0).to(".image-block--lg", {
                        y: -100,
                        ease: "linear",
                    }, 0);
                }

                gsap.utils.toArray(".featured-projects .project-block").forEach((panel, i) => {
                    gsap.to(panel.querySelectorAll(".project-block__image"), {
                        scrollTrigger: {
                            trigger: panel,
                            start: 'top bottom',
                            end: 'center center',
                            scrub: 1, // Smoothly transition the effect as you scroll
                            once: true,
                            toggleActions: "play none none reverse", // Reverse the animation when scrolling up after completion
                            markers: false,
                        },
                        opacity: .6,
                        scale: 1.3,
                        ease: "linear",
                    });
                });
        },
        "(min-width: 1025px)": function() {
                gsap.utils.toArray(".showreel").forEach((showreel, i) => {
                    var showReelTL = gsap.timeline({
                        scrollTrigger: {
                            trigger: showreel,
                            start: "top 60%",
                            end: "bottom bottom",
                            scrub: true,
                            markers: false,
                        },
                    });

                    showReelTL.to(showreel, {
                        scale: 1,
                        // width: "100%"
                    })
                });

            /* ====================== CAREERS BENEFITS ======================  */
                function scrollBenefits() {
                  const benefits = document.querySelector('.benefits');
                  const sliderTrack = document.querySelector('.benefits .number-slider__slider__track');
                  const valuesCards = document.querySelectorAll('.benefits .box');
                  const containerWidth = document.querySelector('.benefits .container').offsetWidth;
                  let trackWidth = 0;

                  // Calculate total width considering padding and margins
                  valuesCards.forEach(card => {
                    const cardStyles = window.getComputedStyle(card);
                    trackWidth += card.offsetWidth + parseFloat(cardStyles.marginLeft) + parseFloat(cardStyles.marginRight);
                  });

                  // Adjust end position based on container width, multiplied by a factor
                  const endPosition = Math.min(trackWidth - (containerWidth * 0.01) , 10000);

                  gsap.timeline({
                    scrollTrigger: {
                      trigger: benefits,
                      start: 'center center',
                      pin: true,
                      end: () => endPosition, // Pin until the end of the slider within the container
                      scrub: 2.5,
                      onUpdate: (self) => {
                        const progress = self.progress;
                        const targetScroll = trackWidth - containerWidth;
                        const scrollAmount = targetScroll * progress;
                        gsap.to(sliderTrack, { x: -scrollAmount, ease: "power2.out" }); // Apply easing directly here
                      },
                      onLeaveBack: () => {
                        ScrollTrigger.getById('benefitsPin').kill(); // Unpin the section
                      }
                    }
                  })
                  .addLabel('start')
                  .to(benefits, {duration: 0.5, autoAlpha: 1}); // Optionally fade in the section
                }

                if($('.benefits').length) {
                    scrollBenefits();
                }

            /* ====================== SERVICE IMAGE/TEXT ANIM ======================  */
                var startPos = ($(window).height() - $('.service-scroller__images').height()) / 2;
                ScrollTrigger.create({
                    trigger: ".service-scroller__wrap",
                    start: "top "+startPos,
                    end: "bottom bottom",
                    pin: ".service-scroller__images",
                    pinSpacing: false,
                });

                gsap.utils.toArray(".service-scroller .service-block").forEach((panel, i) => {
                    var tl = gsap.timeline({
                      scrollTrigger: {
                        trigger: panel,
                        scrub: false,
                        start: "top 25%", // When to start the animation (you can adjust this)
                        end: "center center", // When to end the animation (you can adjust this)

                        onUpdate: function onUpdate(self) {
                          var index = $(panel).attr('data-index');
                          $('.service-scroller__images .service-image').removeClass('active');
                          $('.service-scroller__images .service-image[data-index=' + index + ']').addClass('active');
                        }
                      }
                    });
                })
        },
        "all": function() {

         }
    });

    gsap.to(".hp-masthead h1 span", {
        scrollTrigger:{
            trigger: '.hp-masthead',
            start: "center 80%",
        },
        y: 7,
        opacity: 1,
        ease: "power2.inOut",
        rotate: 0,
        delay: 0.2,
        duration: .5,
    }, "<");

/* ====================== FEATURED PROJECTS ANIMNATION ======================  */
    gsap.utils.toArray(".featured-projects .project-block:not(:last-of-type)").forEach((panel, i) => {
        ScrollTrigger.create({
            trigger: panel,
            start: "top top",
            pin: true,
            pinSpacing: false,
            markers: false,
        });
    });

    gsap.utils.toArray(".featured-projects .project-block").forEach((panel, i) => {
        gsap.to(panel.querySelectorAll(".project-block__title"), {
            scrollTrigger:{
                trigger: panel,
                start: "top 50%",
                markers: false,
            },
            y: 0,
            opacity: 1,
            ease: "power2.inOut",
            rotate: 0,
            delay: 0.2,
            duration: .5,
        }, "<");
    });

/* ====================== HEADING CONTENT ROW ANIMS ======================  */
    if($('.heading-content').length ) {
        gsap.utils.toArray('.heading-content__wrappr').forEach((newsShortcode) => {
            const newsPosts = Array.from(newsShortcode.querySelectorAll(".heading-content-row"));

            newsPosts.forEach((newsPost, index) => {
                const newsContent = newsPost.querySelector(".heading-content-row__content");

                gsap.from(newsContent, {
                    scrollTrigger: {
                        markers: false,
                        once: true,
                        trigger: newsPost,
                        start: "center bottom",
                    },
                    opacity: 0,
                    duration: 1.5
                });
            });
        });
    }


/* ====================== TRUSTED BY ANIM ======================  */
    gsap.to(".trusted-by__image img", {
        scrollTrigger:{
            trigger: '.trusted-by',
            start: "center 80%",
        },
        duration: 1,
        stagger: .1,
        translateY: 0,
        ease: "power2.out"
    }, "<");

/* ====================== NAVIGATION ANIM ======================  */
    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;
    var e = "polygon(0 0, 100% 0, 100% ".concat(1.1 * windowHeight, "px, 0 ").concat(windowHeight, "px");
    const mainLinks = document.querySelectorAll('.nav > li > a');
    const socialLinks = document.querySelectorAll('.navigation__social .social-icon');
    const navImages = document.querySelectorAll('.navigation__images .menu-image:not(:first-of-type)');

    // FORWARD MENU ANIMATION
    var forwardNavAnim = gsap.timeline({ paused: true });
    forwardNavAnim.set(navImages, {
        autoAlpha: 0,
        zIndex: 1,
    });

    forwardNavAnim.set('.navigation__images .menu-image:first-of-type', {
        autoAlpha: 1,
        zIndex: 3,
    })

    forwardNavAnim.to('.navigation', {
        clipPath: e,
        duration: .5,
    });

    forwardNavAnim.to('.navigation__wrapper', {
        scale: 1,
        rotate: 0,
        y: 0,
        x: 0,
        duration: .5,
        opacity: 1,
    }, "<=25%");

    forwardNavAnim.fromTo(mainLinks, {
        rotate: 7,
        yPercent: 100,
        opacity: 0
    }, {
        rotate: 0,
        yPercent: 0,
        opacity: 1,
        stagger: .1,
        ease: "power2.inOut",
        // delay: .5,
        duration: 1,
    }, "<")

    forwardNavAnim.fromTo(socialLinks, {
        rotate: 7,
        yPercent: 100,
        opacity: 0
    }, {
        rotate: 0,
        yPercent: 0,
        opacity: 1,
        stagger: .1,
        ease: "power2.inOut",
        delay: 1,
        duration: 1,
    }, "-=1.75");

    // ON MENU CLOSE ANIMATION
    var backwardsAnim = gsap.timeline({ paused: true });
    backwardsAnim.to('.navigation', {
        clipPath: "polygon(0 0px, 100% 0px, 100% 0px, 0 0px)",
        duration: 1,
        clearProps: "all"
    });

    backwardsAnim.to('.navigation__wrapper', {
        scale: 1.3,
        rotate: -7,
        y: -windowHeight / 2,
        opacity: .3,
        duration: 1,
        clearProps: "all"
    }, "<=25%");

    backwardsAnim.set(navImages, {
       clearProps: "all"
    });

    var navCloseAnim = gsap.timeline({ paused: true });
    navCloseAnim.to('.nav-close', {
        scale: 1,
        duration: .5,
        visibility: 'visible',
        display: 'flex',
        onStart: () => {
          // Before the animation starts, set visibility to visible
          '.nav-close'.style.visibility = 'visible';
        },
        onComplete: () => {
          // After the animation completes, set display to flex
          '.nav-close'.style.display = 'flex';
        }
    });

    $(document).on('click','.header .nav-open',function() {
        if($('body').hasClass('nav-open')) {
            backwardsAnim.progress(0);
            backwardsAnim.play();
            navCloseAnim.reverse();
          } else {
            forwardNavAnim.progress(0);
            forwardNavAnim.play();
            navCloseAnim.play();
          }

        $(this).toggleClass("active");
        $("html, body").toggleClass("nav-open");
        $('.header').toggleClass('header--navopen');
    });


    const elements = document.querySelectorAll('.menu-item');
    elements.forEach(element => {
        element.addEventListener('mouseenter', function(e) {
            const hoveredElementID = e.target.id;
            if (hoveredElementID && hoveredElementID.includes('menu-item')) {
                const strippedID = hoveredElementID.replace('menu-item-', '');
                const relatedMenuImages = document.querySelectorAll(`.menu-image[data-related="${strippedID}"]`);
                const navImages = document.querySelectorAll('.navigation__images .menu-image');
                var hoverAnimation = gsap.timeline({  });

                 hoverAnimation.fromTo(relatedMenuImages[0], {
                    zIndex: 1,
                    autoAlpha: 0,
                    scale: 1.3,
                    rotate: -7,
                    transformOrigin: "center center" // set transform origin to top right corner
                }, {
                    zIndex: 3,
                    autoAlpha: 1,
                    scale: 1,
                    rotate: 0,
                    duration: .5,
                });

                // Animation for navImages excluding relatedMenuImages[0]
                navImages.forEach((image, index) => {
                    const relatedId = image.getAttribute('data-related'); // Get the ID of the image element
                    if (relatedId != strippedID) {
                        hoverAnimation.to(image, {
                            autoAlpha: 0,
                            zIndex: 1,
                            clearProps: "scale, rotate, transformOrigin"
                        }, 0);
                    }
                });
            }
        });
    });

/* ====================== IMAGE LIST ROW ANIM FOR MEET TEAM AND PROJECTS ======================  */
    if (window.innerWidth >= 1024) {
        var imageListRows = document.querySelectorAll('.image-list-row');
        imageListRows.forEach(function(imageListRow) {
            imageListRow.addEventListener('mouseenter', mouseenterFn);
            imageListRow.addEventListener('mouseleave', mouseleaveFn);
            imageListRow.addEventListener('mousemove', mousemoveFn);
        });
    }
    if (window.innerWidth >= 1024) {
    var imageListRows = document.querySelectorAll('.image-list-row');
    imageListRows.forEach(function(imageListRow) {
        imageListRow.addEventListener('mouseenter', mouseenterFn);
        imageListRow.addEventListener('mouseleave', mouseleaveFn);
        imageListRow.addEventListener('mousemove', mousemoveFn);
    });
}

const getMousePos = (e) => {
    let posx = 0;
    let posy = 0;
    posx = e.pageX;
    posy = e.pageY;
    return { x : posx, y : posy }
}

function positionImageMedia(ev) {
    const mousePos = getMousePos(ev);
    const scrollY = window.scrollY;
    console.log(mousePos.y - scrollY);
    gsap.to($('.image-list-row__media'), { duration: 0.5, top: mousePos.y - scrollY -80, left: mousePos.x });
}

function showImage(ev) {
    var imageMedia = ev.currentTarget.querySelector('.image-list-row__media');

    var timeline = gsap.timeline();
    timeline.to(imageMedia, { opacity: 1, duration: 0.5 }, 0); // Animate opacity to 1
}

function hideImage(ev) {
    var imageMedia = ev.currentTarget.querySelector('.image-list-row__media');
    var timeline = gsap.timeline();
    timeline.to(imageMedia, { opacity: 0, duration: 0.5 }, 0); // Animate opacity to 0
}

function mouseenterFn(ev) {
    showImage(ev);
}

function mouseleaveFn(ev) {
    hideImage(ev);
}

function mousemoveFn(ev) {
    positionImageMedia(ev);
}

/* ====================== CASE STUDY GSAP ELEMENTS ======================  */
    if($('body').hasClass('single-project') && $('.p-mobile-scroll').length > 0) {
        var duration = $('.p-mobile-scroll__top').height() + $(window).height();

        var mobileScreensScroll = gsap.timeline({
            scrollTrigger: {
              trigger: ".p-mobile-scroll",
              start: "top 80%", // adjust as needed
              duration: duration,
              scrub: 2, // Smoothly animates elements as you scroll
              markers: false,
            }
        })

        mobileScreensScroll.to(".p-mobile-scroll__top", {
            x: -350,
            ease: "linear",
        }, 0).to(".p-mobile-scroll__bottom", {
            x: 350,
            ease: "linear",
        }, 0);
    }

/* ====================== MASTHEAD IMAGE ANIM ======================  */
      if($(".masthead--cover .masthead__image").length) {
        var mastheadTL = gsap.timeline({
          scrollTrigger: {
            trigger: '.masthead--cover',
            start: 'top',
            scrub: true,
            end: 'bottom top',
          },
        });

        // Add animations to the timeline
        mastheadTL.to(".masthead__image", {
          scale: 1.075,
          opacity: 0,
          ease: "linear",
        }).to(".masthead__text", {
          y: "150",
          ease: "linear",
        }, "<");
      }

/* ====================== PROJECT PARALLAX IMAGE ======================  */
    // Check if the conditions are met
    if ($('body').hasClass('single-project') && $('img.p-parallax-image__img').length > 0) {
        // Select all instances of 'img.p-parallax-image__img'
        $('img.p-parallax-image__img').each(function(index, element) {
            // Get the specific trigger for this instance
            var triggerSelector = $(element).closest('.p-parallax-image__wrapper:not(.p-parallax-image__wrapper--fixed):not(.p-parallax-image__wrapper--full)');

            // Apply GSAP animation to the current instance
            gsap.to(element, {
                scrollTrigger: {
                    trigger: triggerSelector,
                    start: 'top bottom',
                    end: 'bottom bottom 10%',
                    scrub: 2, // Smoothly transition the effect as you scroll
                    once: true,
                    markers: false,
                },
                scale: '1', // Adjust the vertical movement based on your preference
                ease: "linear",
            });
        });
    }

// Check if the conditions are met
if ($('body').hasClass('single-project') && $('div.p-parallax-image__img').length > 0) {
    // Select all instances of 'div.p-parallax-image__img'
    $('div.p-parallax-image__img').each(function(index, element) {
        // Get the specific trigger for this instance
        var triggerSelector = $(element).closest('.p-parallax-image__wrapper');

        // Attach scroll event to the window for this instance
        $(window).on('scroll', function() {
            var elementTop = triggerSelector.offset().top;
            var scrollTop = $(document).scrollTop();

            // Update class based on scroll position
            if (scrollTop >= elementTop) {
                triggerSelector.addClass('unfix');
            } else {
                triggerSelector.removeClass('unfix');
            }
        });
    });
}

/* ====================== PROJECT MOBILE IMAGES ANIM ======================  */
    if($('body').hasClass('single-project') && $('.p-mobile-images').length > 0) {
     gsap.utils.toArray(".p-mobile-images__image").forEach((images, i) => {
        var showReelTL = gsap.timeline({
            scrollTrigger: {
                trigger: '.p-mobile-images',
                start: "top 80%",
                end: "top center",
                scrub: true,
                once: true,
                markers: false,
            },
        });

        showReelTL.to(images, {
            y: 0,
            opacity: 1,
            ease: "linear",
        })
     });
    }

/* ====================== PROJECT IMAGE SLIDER ANIM ======================  */
    if($('body').hasClass('single-project') && $('.p-image-slider').length > 0) {
        gsap.to('.p-image-slider__slider', {
            scrollTrigger: {
                trigger: '.p-image-slider',
                start: 'top bottom',
                end: 'top center',
                scrub: 1, // Smoothly transition the effect as you scroll
                once: true,
                markers: false,
            },
            y: 0,
            x: 0,
            opacity: 1,
            ease: "linear",
        });
    }

/* ====================== MARQUEE ANIM ======================  */
    const marquees = document.querySelectorAll('.marquee');
    marquees.forEach((marquee, index) => {
        const duration = parseInt(marquee.getAttribute("duration"), 10) || 5;
        const marqueeContent = marquee.firstElementChild;
        if (!marqueeContent) {
            return;
        }

        // DUPLICATE MARQUE-CONTENT AND APPEND
        const marqueeContentClone = marqueeContent.cloneNode(true);
        marquee.append(marqueeContentClone);

        let tween;

        const playMarquee = () => {
            let progress = tween ? tween.progress() : 0;

            tween && tween.progress(0).kill();
            const width = parseInt(getComputedStyle(marqueeContent).getPropertyValue("width"), 10);
            const gap = parseInt(getComputedStyle(marqueeContent).getPropertyValue("column-gap"), 10);
            const distanceToTranslate = -1 * (gap + width);
            const direction = -1; // Scroll to the right for the second instance

            tween = gsap.fromTo(marquee.children,
                {
                    x: direction * distanceToTranslate
                },
                {
                    x: 0, duration,
                    ease: "none", repeat: -1
                }
            );
            tween.progress(progress);
        };

        playMarquee();

        function debounce(func) {
          var timer;
          return function (event) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(
              () => {
                func();
              },
              500,
              event
            );
          };
        }
        window.addEventListener("resize", debounce(playMarquee));
    });


/* ====================== FOOTER ANIM ======================  */

    // if (isLargeScreen) {
    //     gsap.to('.footer', {
    //       scrollTrigger: {
    //         trigger: '.footer-pusher',
    //         start: 'top bottom',
    //         end: '+=80%', // Increase the end value to make the animation smoother
    //         scrub: true,
    //         markers: false,
    //       },
    //       y: '0',
    //       // opacity: 1,
    //       duration: 1,
    //       ease: 'linear',
    //     });
    // };

    $('.the-team__filter button').on('click', function () {
        var filterValue = $(this).data('filter');
        $('.the-team__filter button').not($(this)).removeClass('active');
        $(this).addClass('active');

        if (filterValue === 'all') {
            $('.image-list-row').show();
        } else {
            $('.image-list-row').hide();
            $('.image-list-row[data-category="' + filterValue + '"]').show();
        }

        ScrollTrigger.refresh();
    });

    $('#team_filter').on('change', function() {
        var selectedCategory = $(this).val();
        if (selectedCategory === 'all') {
            $('.image-list-row').show(); // Show all rows if 'All' is selected
        } else {
            $('.image-list-row').hide();
            $('.image-list-row[data-category="' + selectedCategory + '"]').show(); // Show rows matching the selected category
        }
    });

/* ====================== MAGNET ======================  */
    function magnets() {
        if (window.matchMedia("(pointer:fine)").matches) {
            var magnets = document.querySelectorAll('.magnetic');

            magnets.forEach((magnet) => {
                var strength = magnet.dataset.strength ? parseFloat(magnet.dataset.strength) : 200;

                magnet.addEventListener('mousemove', function(event) {
                    moveMagnet(event, strength);
                });

                magnet.addEventListener('mouseout', function(event) {
                    gsap.to(event.currentTarget, {
                        duration: 1,
                        x: 0,
                        y: 0,
                        ease: "power4.out"
                    });
                });
            });

            function moveMagnet(event, strength) {
                var magnetButton = event.currentTarget;
                var bounding = magnetButton.getBoundingClientRect();
                gsap.to(magnetButton, {
                    duration: 1,
                    x: (((event.clientX - bounding.left) / magnetButton.offsetWidth) - 0.5) * strength,
                    y: (((event.clientY - bounding.top) / magnetButton.offsetHeight) - 0.5) * strength,
                    ease: "power4.out"
                });
            }
        }
    }

    magnets();
