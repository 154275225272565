var $ = require("jquery");
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

/* ====================== BRANDS SLIDER ======================  */
 if($('body.home .services-slider').length) {
      var servicesSlider = new Swiper('.services-slider .swiper', {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: true,
            breakpoints: {
              430: {
                slidesPerView: 1.1,
              },
              768: {
                  slidesPerView: 2,
              },
              1024: {
                  slidesPerView: 2.93,
                  spaceBetween: 30,
              },
            },
      });

      function destroySlider() {
          if (window.innerWidth < 767) {
              if (servicesSlider && servicesSlider.destroy) {
                  servicesSlider.destroy();
              }
          }
      }

      // Call the destroySlider function on window resize
      window.addEventListener('resize', destroySlider);

      // Call the destroySlider function initially to check on page load
      destroySlider();
}

 if($('body:not(.home) .services-slider').length) {
      var servicesSlider = new Swiper('.services-slider .swiper', {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: true,
            pagination: {
                el: ".related-services__pagination",
                type: "progressbar",
            },
            breakpoints: {
              430: {
                slidesPerView: 1.1,
              },
              768: {
                  slidesPerView: 1.5,
              },
              1024: {
                  slidesPerView: 2.93,
                  spaceBetween: 30,
              },
            },
      });
}

/* ====================== VALUES CARD SLIDER ======================  */
// if($('.our-values__slider').length) {
//       const valuesSlider = new Swiper('.our-values__slider .swiper', {
//             slidesPerView: 2.25,
//             spaceBetween: 30,
//             loop: true,
//       });
// }

/* ====================== NUMBER SLIDER ======================  */
if($('.number-slider__slider .swiper').length) {
      const numberSlider = new Swiper('.number-slider__slider .swiper', {
            slidesPerView: 1,
            spaceBetween: 15,
            pagination: {
                el: ".number-slider__pagination",
                type: "progressbar",
            },
            breakpoints: {
              430: {
                slidesPerView: 1.1,
              },
              768: {
                  slidesPerView: 2,
              },
              1024: {
                  slidesPerView: 2.15,
                  spaceBetween: 30,
              },
               1200: {
                  slidesPerView: 3,
                  spaceBetween: 30,
              },
            },
      });
}

/* ====================== SERVICE CHILD IMAGE SLIDER ======================  */
if($('.sc-image-slider__slider').length) {
      const serviceImageSlider = new Swiper('.sc-image-slider__slider .swiper', {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 15,
        loop: true,
        breakpoints: {
          1025: {
            centeredSlides: false,
            slidesPerView: 2.2,
            spaceBetween: 30,
          },
        },
      });
}

/* ====================== SERVICE CHILD IMAGE SLIDER ======================  */
const projectImageSlider = new Swiper('.p-image-slider__slider:not(.p-image-slider__slider--lg) .swiper', {
    slidesPerView: 1.15,
    loop: true,
    spaceBetween: 15,
    pagination: {
      el: ".p-image-slider__pagination",
      type: "progressbar",
    },
    breakpoints: {
      768: {
          slidesPerView: 1.75,
          spaceBetween: 30,
      },
      1025: {
          loop: false,
          slidesPerView: 2,
          spaceBetween: 30,
      },
    },

});

const projectImageSliderLG = new Swiper('.p-image-slider__slider--lg .swiper', {
    slidesPerView: 1.15,
    loop: true,
    spaceBetween: 30,
    pagination: {
      el: ".p-image-slider__pagination",
      type: "progressbar",
    },
    breakpoints: {
      1024: {
          loop: true,
          slidesPerView: "auto",
          centeredSlides: true,
      },
    },
});


/* ====================== RESULTS SLIDER ======================  */
if($('.p-results__slider').length) {
      const resultsSlider = new Swiper('.p-results__slider .swiper', {
          slidesPerView: 1.1,
          spaceBetween: 15,
            breakpoints: {
              650: {
                  slidesPerView: 2.1,
              },
              1024: {
                  slidesPerView: 2.5,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            },
      });
}

